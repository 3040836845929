
export default {
  name: 'WechatButton',
  
  methods: {
    openWechat() {
        this.$store.commit('modal/setShowWechatModal', true);
    }
  },
}
